import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {ExtendedSwitch} from "../core/route/extended.switch";
import {Col, Row, Tabs, Typography} from "antd";
import {ComponentPropsFromRoute} from "../../routers/routers";
import history from "../../utils/history";
import {isAllowed} from "../../utils/helpers";
import {RootStoreContext} from "../../stores/root/root";


interface MyProfileProps extends ComponentPropsFromRoute {
};
export const MyProfile: React.FC<MyProfileProps> = ({routeChildren}) => {
    const {authStore: {attributes}} = useContext(RootStoreContext)

    const {t} = useTranslation('translation', {useSuspense: false});
    const location = useLocation();

    return (
        <div className="page_content">
            <Row className="content" style={{width: "100%", marginRight: 0, marginLeft: 0}} gutter={[20, 20]}
                 align="stretch">
                <Col span={24}>
                        <Typography.Title
                            level={1}
                            className="main-title"
                            style={{marginBottom: 30}}
                        >
                            {t("MY_PROFILE.TITLE")}
                        </Typography.Title>
                        <Tabs
                            tabPosition={"top"}
                            activeKey={location.pathname}
                            onTabClick={(key) => history.push(key)}
                        >
                            {routeChildren!.map((item) => isAllowed(attributes, item.access) ? (
                                <Tabs.TabPane tab={t(item.systemName)} key={item.path}/>
                            ) : null)}
                        </Tabs>
                        <ExtendedSwitch routers={routeChildren!}/>
                </Col>
            </Row>
        </div>
    );
};
