import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, Form, Popconfirm, Row, Space, Table, Tooltip, Skeleton, message } from "antd";
import { EditableCell } from "../../../core/commons/editable-cell/editable.cell";
import { CartCreateCalculatedPrice } from "../../../../models/cart/create/cart.create";
import { Amount } from "../../../../models/amount/amount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRequiredLayers } from "../../../../utils/context";
import { CartCreateStore, CartStoreContext } from "../cart.create.store";
import { CartCreateController, CartCreateControllerContext } from "../cart.create.controller";
import { CartCreateOrderQuantity } from "../../../../models/cart/create/quantity/cart.create.order.quantity";
import { CartCreateTableLastRecord, CartCreateTableRecord } from "../../../../models/cart/create/records/records";
import { CartCreateArticleModal } from "../cart-create-article-model/basket.create.article.modal";
import { useVT } from "virtualizedtableforantd4";
import { ColumnsType } from "antd/lib/table";
import { displayCustomPrice } from "../../../../utils/helpers";
import i18n from "i18next";
import "./cart.create.table.scss";
import { Link } from "react-router-dom";
import {Price} from "../../../core/commons/price/price";

export const CartCreateTable: React.FC = observer(() => {
    const { store, controller } = useRequiredLayers<CartCreateStore, CartCreateController>(
        CartStoreContext,
        CartCreateControllerContext
    );
    const { t } = useTranslation("translation", { useSuspense: false });
    const [modalShown, setModalShown] = useState<boolean>(false);

    useEffect(() => {
        i18n.on("languageChanged", controller.onLngChange);
        return () => i18n.off("languageChanged", controller.onLngChange);
    }, []);

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState<string | number>("");

    const isRowDisabled = (row: CartCreateTableRecord): boolean => !!(!row.quantity.qty && row.id);

    const generateColumns = (): ColumnsType<CartCreateTableRecord> => {
        const columns: any[] = [
            {
                title: t("ARTICLES.TITLE_SINGLE"),
                dataIndex: "article_name",
                key: "article_name",
                width: "130px",
                render: (item: string, row: CartCreateTableRecord) => {
                    return item ? (
                        item.toUpperCase() === "DELIVERY" ? (
                            t("DELIVERY.TITLE")
                        ) : (
                            <Link
                                to={"/catalog/article/" + item}
                                className={isRowDisabled(row) ? "disabled link article-link" : "link article-link"}
                            >
                                {item}
                            </Link>
                        )
                    ) : null;
                },
            },
            {
                title: t("DESCRIPTION"),
                dataIndex: "article_description",
                key: "article_description",
                width: "230px",
                className: "cart-article-description-column",
                render: (item: string, row: CartCreateTableRecord) => (
                    <span className={isRowDisabled(row) ? "disabled" : undefined}>{item}</span>
                ),
            },
        ];

        columns.push({
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            width: "90px",
            render: (item: CartCreateCalculatedPrice | undefined, row: CartCreateTableRecord) =>
                item ? (
                    <span className={isRowDisabled(row) ? "disabled" : undefined}>
                        {displayCustomPrice({
                            price: item.amount,
                        })}
                    </span>
                ) : null,
        });

        store.warehousesAll.forEach((warehouse) => {
            if (store.defaultWarehouses.includes(warehouse.id)) {
                columns.push({
                    title: warehouse.name,
                    dataIndex: warehouse.id,
                    key: warehouse.id,
                    width: "95px",
                    render: (item: {stockAmount: number, backorderAmount: number}, row: CartCreateTableRecord) => {
                        if (item) {
                            return (
                                <span className={isRowDisabled(row) ? "disabled" : undefined}>
                                    {item.stockAmount}
                                    {item.backorderAmount > 0 ? (
                                        <div className={"backorder-qty"}>
                                            +{item.backorderAmount}
                                            <span className={"descr"}> (backorder)</span>
                                        </div>
                                ) : null}
                                </span>
                            )
                        }
                        return '-'
                    },
                });
            }
        });

        columns.push({
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
            width: "100px",
            editable: true,
            render: (item: CartCreateOrderQuantity, row: CartCreateTableRecord) =>
                item ? (
                    <span className={isRowDisabled(row) ? "disabled" : undefined} style={{ whiteSpace: "nowrap" }}>
                        {item.qty} / {item.requestedQty}
                    </span>
                ) : null,
        });

        columns.push({
            title: t("TOTAL_AMOUNT"),
            dataIndex: "total_amount",
            key: "total_amount",
            width: "100px",
            render: (item: Amount | undefined, row: CartCreateTableRecord) =>
                item ? <span className={isRowDisabled(row) ? "disabled" : undefined}><Price value={item} /></span> : null,
        });

        columns.push({
            title: t("ACTION"),
            dataIndex: "action",
            key: "action",
            width: "80px",
            className: "cart-action-column",

            render: (_: any, record: CartCreateTableRecord & CartCreateTableLastRecord) => {
                const editable = isEditing(record);
                if (record.article_name) {
                    return (
                        <Space direction="horizontal" size={[5, 5]}>
                            {editable ? (
                                <Tooltip placement="topRight" title={t("SAVE")}>
                                    <Button
                                        className="btn"
                                        onClick={() => save(record)}
                                        type="default"
                                        size={"small"}
                                        icon={<FontAwesomeIcon icon={["fas", "check"]} />}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip placement="topRight" title={t("EDIT")}>
                                    <Button
                                        className="btn"
                                        disabled={editingKey !== ""}
                                        onClick={() => edit(record)}
                                        type="default"
                                        size={"small"}
                                        icon={<FontAwesomeIcon icon={["fas", "pencil-alt"]} />}
                                    />
                                </Tooltip>
                            )}

                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => controller.removeItem(record.id)}
                                placement="left"
                                okText={t("YES")}
                                cancelText={t("NO")}
                            >
                                <Tooltip placement="topRight" title={t("REMOVE")}>
                                    <Button
                                        className="btn-remove btn"
                                        disabled={editingKey !== ""}
                                        type="default"
                                        size={"small"}
                                        icon={<FontAwesomeIcon icon={["fas", "times"]} />}
                                    />
                                </Tooltip>
                            </Popconfirm>
                        </Space>
                    );
                }

                return <></>;
            },
        });
        return columns;
    };

    const isEditing = (record: CartCreateTableRecord & CartCreateTableLastRecord) => record.id === editingKey;

    const edit = (record: CartCreateTableRecord & CartCreateTableLastRecord & { id: React.Key }) => {
        form.setFieldsValue({ quantity: record.quantity.requestedQty });
        setEditingKey(record.id);
    };

    const save = async (record: CartCreateTableRecord & CartCreateTableLastRecord) => {
        try {
            const row = await form.validateFields();
            if (record.price.amount.value !== row.price) {
                await controller.updateItem(record.id, { qty: row.quantity });
            }
            setEditingKey("");
        } catch (errInfo) {
            message.error(t("ERROR"));
        }
    };

    const columns: any[] = generateColumns();

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: CartCreateTableRecord & CartCreateTableLastRecord) => ({
                record,
                inputType: "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const y = 500;
    const [vt, setComponent] = useVT(() => ({ scroll: { y }, debug: true }), []);
    setComponent({
        body: {
            cell: EditableCell,
        },
    });

    return (
        <>
            <Space
                direction="vertical"
                style={{
                    width: "100%",
                    maxWidth: "100%",
                    overflowY: "auto",
                    marginBottom: 15,
                }}
            >
                <div className={"card-table-buttons"}>
                    {store.loading ? (
                        <Space direction="horizontal" size={[10, 10]}>
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                            <Skeleton.Button active />
                        </Space>
                    ) : (
                        <>
                            <Button
                                onClick={controller.createOrder}
                                type="primary"
                                loading={store.checkoutButtonDisabled}
                                disabled={store.cart?.qty === 0}
                            >
                                {t("ORDERS.CHECKOUT")}
                            </Button>
                            <Popconfirm
                                title={t("ARE_YOU_SURE")}
                                onConfirm={() => controller.clearCart()}
                                placement="top"
                                okText={t("YES")}
                                cancelText={t("NO")}
                                disabled={store.tableData.length === 1}
                            >
                                <Button disabled={store.tableData.length === 1} type="primary" danger>
                                    {t("CART.CLEAR")}
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                </div>
                <Form form={form} component={false}>
                    <Table<CartCreateTableRecord>
                        className="basket-table"
                        rowKey={"id"}
                        components={store.tableData.length === 1 ? undefined : vt}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        dataSource={store.tableData.length !== 1 ? [...store.tableData] : []}
                        loading={store.previewLoading || store.loading}
                        scroll={store.tableData.length === 1 ? { x: true } : { x: true, y }}
                        pagination={false}
                    />
                </Form>
            </Space>
            <Row justify={"center"}>
                {store.loading ? (
                    <Skeleton.Button active />
                ) : (
                    <Button type="primary" onClick={() => setModalShown(true)}>
                        {t("CART.ADD_ROW")}
                    </Button>
                )}
            </Row>
            {modalShown ? <CartCreateArticleModal onClose={() => setModalShown(false)} /> : null}
        </>
    );
});
