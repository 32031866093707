import { Cart } from "../components/cart/cart";
import { Documents } from "../components/documents/documents";
import { MyProfile } from "../components/my-profile/my-profile";
import { OrdersList } from "../components/orders/orders-list/orders.list";
import { Index } from "../components/index";
import { Order } from "../components/orders/order/order";
import { Login } from "../components/security/login/login";
import { Registration } from "../components/security/registration/registration";
import { CartCreate } from "../components/cart/create/cart.create";
import { CartCheckout } from "../components/cart/checkout/cart.checkout";

import { ReactComponent as CartIcon } from "../components/navigation/navigation-icons/cart.svg";
import { ReactComponent as CatalogIcon } from "../components/navigation/navigation-icons/catalog.svg";
import { ReactComponent as ClaimsIcon } from "../components/navigation/navigation-icons/claims.svg";
import { ReactComponent as ProductionIcon } from "../components/navigation/navigation-icons/production.svg";
import { ReactComponent as IssuesIcon } from "../components/navigation/navigation-icons/issues.svg";
import { ReactComponent as AnnouncementsIcon } from "../components/navigation/navigation-icons/announcements.svg";
import { ReactComponent as DocumentsIcon } from "../components/navigation/navigation-icons/documents.svg";
import { ReactComponent as MyProfileIcon } from "../components/navigation/navigation-icons/my-profile.svg";
import { ReactComponent as OrdersIcon } from "../components/navigation/navigation-icons/orders.svg";
import { ReactComponent as WishListIcon } from "../components/navigation/navigation-icons/wishlist.svg";
import { ReactComponent as MissingItemsIcon } from "../components/navigation/navigation-icons/missing-items.svg";
import { ReactComponent as InTransitIcon } from "../components/navigation/navigation-icons/in_transit.svg";
import { ReactComponent as WikiIcon } from "../components/navigation/navigation-icons/wiki.svg";

import React from "react";
import { NotFound } from "../components/security/not-found/not.found";
import { InvoicesList } from "../components/invoices/invoices-list/invoices.list";
import { Invoice } from "../components/invoices/invoice/invoice";
import { ForgotPassword } from "../components/security/login/forgot-password/forgot.password";
import { Addresses } from "../components/my-profile/addresses/addresses";
import { Contacts } from "../components/my-profile/contacts/contacts";
import { ForgotPasswordComplete } from "../components/security/login/forgot-password/complete/forgot.password.complete";
import { Integrations } from "../components/my-profile/integrations/integrations";
import { Accesses } from "../components/my-profile/accesses/accesses";
import { MergedInvoicesList } from "../components/invoices/merged-invoices-list/merged.invoices.list";
import { MergedInvoice } from "../components/invoices/merged-invoice/merged.invoice";
import {ClaimView} from "../components/claims/view/claim.view";
import {ClaimList} from "../components/claims/list/claim.list";
import {ClaimsCreate} from "../components/claims/create/claims.create";
import {ClaimsCreateSelectType} from "../components/claims/create/claims.create.select-type";
import {AnnouncementsList} from "../components/announcements/list/announcements.list";
import {AnnouncementsListView} from "../components/announcements/list/announcements.list.view";
import {AnnouncementView} from "../components/announcements/view/announcements.view";
import {WishlistList} from "../components/wish-list/wish-list-list/wish-list.list";
import {MissingItems} from "../components/wish-list/missing-items/missing.items";
import {InTransit} from "../components/in-transit/in-transit";
import {OrdersMain} from "../components/orders/orders-main/orders.main";
import {CreditInvoicesList} from "../components/invoices/credit-invoices-list/credit.invoices.list";
import {CreditInvoice} from "../components/invoices/credit-invoice/invoice";
import {WikiMain} from "../components/wiki/main/main";
import {WikiDefaultPage} from "../components/wiki/page/default/default";
import {WikiViewPage} from "../components/wiki/page/view/view";
import {MissingItemsWrapper} from "../components/wish-list/missing-items/missing.items.wrapper";
import {IssueView} from "../components/issues/view/tasks.view";
import {CreateIssue} from "../components/issues/create-issue/tasks.create-issue";
import {IssuesList} from "../components/issues/list/my-issues.list";
import {Catalog} from "../components/catalog/catalog/catalog";
import {Article} from "../components/article/article";
import {BackorderList} from "../components/backorder/backorder-list/backorder-list";
import {ProductionRequestList} from "../components/production/request/list/request.list";
import {ProductionRequestCreate} from "../components/production/request/create/request.create";
import {ProductionRequestListWrapper} from "../components/production/request/list/request.list.wrapper";
import {ProductionRequestView} from "../components/production/request/view/request.view";

export interface ComponentPropsFromRoute {
    requiredConfigs?: { [key: string]: any };
    routeChildren?: RouteConfig[];
    name?: string;
}

export interface RouteConfig {
    systemName: string;
    path: string;
    component: any;
    authRequired?: boolean;
    viewInNavigationPanel?: boolean;
    hiddenTitleInNavigationPanel?: boolean;
    icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    exact?: boolean;
    access?: string;
    children?: RouteConfig[];
    configData?: { [key: string]: any };
    name?: string;
    hide?: boolean;
    disallowAttributs?: string[];
    separated?: boolean;
}
export const routers: RouteConfig[] = [
    {
        systemName: "FORGOT_PASSWORD.TITLE",
        path: "/forgot-password",
        component: ForgotPassword,
        exact: true,
    },
    {
        systemName: "FORGOT_PASSWORD.TITLE",
        path: "/forgot-password/complete",
        component: ForgotPasswordComplete,
        exact: true,
    },
    {
        systemName: "Login",
        path: "/login",
        component: Login,
    },
    {
        systemName: "Registration",
        path: "/sign-up",
        component: Registration,
    },
    {
        systemName: "CATALOG.TITLE",
        path: "/catalog",
        access: "article_list",
        component: Catalog,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: CatalogIcon,
    },
    {
        systemName: "ARTICLES.TITLE_SINGLE",
        path: "/catalog/article/:id",
        component: Article,
        access: "article_view",
        authRequired: true,
        viewInNavigationPanel: false,
    },
    {
        systemName: "ORDERS.TITLE",
        path: "/orders",
        component: OrdersMain,
        access: "order_list",
        authRequired: true,
        viewInNavigationPanel: true,
        icon: OrdersIcon,
        name: "orders_main",
        exact: false,
        children: [
            {
                systemName: "ORDERS.TITLE",
                path: "/orders",
                component: OrdersList,
                access: "order_list",
                authRequired: true,
                name: "orders",
                exact: true,
                configData: {
                    type: 'regular'
                }
            },
            {
                systemName: "ORDERS.PRE-ORDERS.TITLE",
                path: "/orders/pre-orders",
                component: OrdersList,
                access: "order_create_reservation",
                authRequired: true,
                name: "orders-pre_orders",
                exact: true,
                configData: {
                    type: 'reservation'
                }
            },
            {
                systemName: "ORDERS.BACKORDERS.TITLE",
                path: "/orders/backorders",
                component: OrdersList,
                access: "order_create_backorder",
                authRequired: true,
                name: "orders-backorders",
                exact: true,
                configData: {
                    type: 'backorder'
                }
            },
            {
                systemName: "INVOICES.TITLE",
                path: "/orders/invoices",
                component: InvoicesList,
                access: "order_list",
                disallowAttributs: ["merged_invoice_list"],
                authRequired: true,
                name: "invoices",
                exact: true,
            },
            {
                systemName: "INVOICES.TITLE",
                path: "/orders/final-invoices",
                component: MergedInvoicesList,
                access: "merged_invoice_list",
                authRequired: true,
                name: "merged-invoices",
                exact: true,
            },
            {
                systemName: "CREDITS.TITLE",
                path: "/orders/credit-invoices",
                component: CreditInvoicesList,
                access: "order_list",
                authRequired: true,
                name: "credit-invoices",
                exact: true,
            },
            {
                systemName: "BACKORDER.TITLE",
                path: "/orders/backorder",
                component: BackorderList,
                access: "order_backorder",
                authRequired: true,
                name: "credit-invoices",
                exact: true,
            }
        ]
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/invoices/:id",
        component: Invoice,
        access: "order_view",
        disallowAttributs: ["merged_invoice_list"],
        authRequired: true,
        name: "invoice",
        exact: true,
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/final-invoices/:id",
        component: MergedInvoice,
        access: "merged_invoice_list",
        authRequired: true,
        name: "merged-invoice",
        exact: true,
    },
    {
        systemName: "INVOICES.DATA.TITLE",
        path: "/credit-invoices/:id",
        component: CreditInvoice,
        access: "order_view",
        authRequired: true,
        name: "credit-invoice",
        exact: true,
    },
    {
        systemName: "ORDERS.ORDER.TITLE",
        path: "/order/:id",
        component: Order,
        access: "order_view",
        authRequired: true,
        name: "order",
        exact: true,
    },
    {
        systemName: "CART.TITLE",
        path: "/cart",
        component: Cart,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: CartIcon,
        exact: false,
        name: "cart",
        children: [
            {
                systemName: "ORDERS.CREATE_BASIC.TITLE",
                path: "/cart",
                component: CartCreate,
                authRequired: true,
                viewInNavigationPanel: false,
                exact: true,
                name: "cart",
                configData: {
                    type: 'regular'
                }
            },
            {
                systemName: "ORDERS.CREATE_RESERVATION.TITLE",
                path: "/cart/reservation",
                component: CartCreate,
                authRequired: true,
                viewInNavigationPanel: false,
                exact: true,
                access: "order_create_reservation",
                name: "cart-reservation",
                configData: {
                    type: 'reservation'
                }
            },
            {
                systemName: "ORDERS.CREATE_BACKORDER.TITLE",
                path: "/cart/backorder",
                component: CartCreate,
                authRequired: true,
                viewInNavigationPanel: false,
                exact: true,
                access: "order_create_backorder",
                name: "cart-backorder",
                configData: {
                    type: 'backorder'
                }
            },
            {
                systemName: "CART.TITLE",
                path: "/cart/checkout",
                component: CartCheckout,
                authRequired: true,
                viewInNavigationPanel: false,
                exact: true,
                name: "apply_order",
            },
        ],
    },
    {
        systemName: "CLAIMS.CREATE.TITLE",
        path: "/claims/new",
        component: ClaimsCreateSelectType,
        authRequired: true,
        exact: true,
    },
    {
        systemName: "CLAIMS.CREATE.TITLE",
        path: "/claims/new/:type",
        component: ClaimsCreate,
        authRequired: true,
        exact: true,
    },
    {
        systemName: "CLAIMS.VIEW",
        path: "/claims/:id",
        component: ClaimView,
        authRequired: true,
        exact: false,
    },
    {
        systemName: "CLAIMS.TITLE",
        path: "/claims",
        component: ClaimList,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: ClaimsIcon,
        exact: false,
    },
    {
        systemName: "In Transit",
        path: "/in-transit",
        access: "order_create_reservation",
        component: InTransit,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: InTransitIcon,
        exact: false,
        separated: true
    },
    {
        systemName: "Missing items",
        path: "/missing-items",
        access: "order_missing_items",
        component: MissingItemsWrapper,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: MissingItemsIcon,
        exact: false,
        children: [
            {
                systemName: "Active",
                path: "/missing-items",
                component: MissingItems,
                configData: {active: true}
            },
            {
                systemName: "Hidden",
                path: "/missing-items/hidden",
                component: MissingItems,
                configData: {active: false}
            },
        ]
    },
    {
        systemName: "DOWNLOADS.TITLE",
        path: "/downloads",
        component: Documents,
        // access: "",
        authRequired: true,
        viewInNavigationPanel: true,
        icon: DocumentsIcon,
        hide: true,
        separated: true
    },
    {
        systemName: "WISH_LIST.TITLE",
        path: "/wish-list",
        access: "order_list",
        component: WishlistList,
        authRequired: true,
        viewInNavigationPanel: true,
        hiddenTitleInNavigationPanel: true,
        icon: WishListIcon,
        exact: false,
    },
    {
        systemName: "WIKI.TITLE",
        path: "/wiki",
        component: WikiMain,
        access: "wiki_view",
        authRequired: true,
        viewInNavigationPanel: true,
        hiddenTitleInNavigationPanel: true,
        icon: WikiIcon,
        exact: false,
        hide: true,
        children: [
            {
                systemName: "WIKI.TITLE",
                path: "/wiki",
                component: WikiDefaultPage,
                name: "default_page",
                authRequired: true,
                exact: true,
            },
            {
                systemName: "WIKI.VIEW.TITLE",
                path: "/wiki/page/:id",
                component: WikiViewPage,
                name: "view_page",
                authRequired: true,
                exact: true,
            }
        ]
    },
    {
        systemName: "ACCOUNTS.TITLE",
        path: "/my-profile",
        component: MyProfile,
        // access: "",
        authRequired: true,
        viewInNavigationPanel: true,
        hiddenTitleInNavigationPanel: true,
        icon: MyProfileIcon,
        hide: true,
        exact: false,
        children: [
            {
                systemName: "CONTACTS.TITLE",
                path: "/my-profile",
                component: Contacts,
                access: "account_contact_management",
                authRequired: true,
                viewInNavigationPanel: false,
                hide: true,
                exact: true,
            },
            {
                systemName: "BILLING.ADDRESS",
                path: "/my-profile/billing-address",
                component: Addresses,
                access: "account_address_management",
                authRequired: true,
                viewInNavigationPanel: false,
                hide: true,
                exact: true,
                configData: {
                    type: "billing",
                },
            },
            {
                systemName: "SHIPPING.ADDRESS",
                path: "/my-profile/shipping-address",
                component: Addresses,
                access: "account_address_management",
                authRequired: true,
                viewInNavigationPanel: false,
                hide: true,
                exact: true,
                configData: {
                    type: "shipping",
                },
            },
            {
                systemName: "CLIENT.INTEGRATIONS.TITLE",
                path: "/my-profile/integrations",
                component: Integrations,
                access: "account_integrations_list",
                authRequired: true,
                viewInNavigationPanel: false,
                hide: true,
                exact: true,
            },
            {
                systemName: "ACCOUNTS.TITLE",
                path: "/my-profile/accounts",
                component: Accesses,
                access: "user_list",
                authRequired: true,
                viewInNavigationPanel: false,
                hide: true,
                exact: true,
            },
            // {
            //     systemName: "MARKETING_AGREEMENT.TITLE",
            //     path: "/my-profile/agreements",
            //     component: Agreements,
            //     // access: "user_list",
            //     authRequired: true,
            //     viewInNavigationPanel: false,
            //     hide: true,
            //     exact: true,
            // },
        ],
    },
    {
        systemName: "ANNOUNCEMENTS.TITLE",
        path: "/announcements",
        component: AnnouncementsList,
        authRequired: true,
        viewInNavigationPanel: true,
        hiddenTitleInNavigationPanel: true,
        icon: AnnouncementsIcon,
        exact: false,
        children: [
            {
                systemName: "ANNOUNCEMENTS.RELEVANT.TITLE",
                path: "/announcements/relevant",
                component: AnnouncementsListView,
                authRequired: true,
                exact: true,
                configData: {
                    type: "relevant",
                },
            },
            {
                systemName: "ANNOUNCEMENTS.HISTORY.TITLE",
                path: "/announcements/history",
                component: AnnouncementsListView,
                authRequired: true,
                exact: true,
                configData: {
                    type: "history",
                },
            },
        ]
    },
    {
        systemName: "ANNOUNCEMENT.TITLE",
        path: "/announcement/:id",
        component: AnnouncementView,
        authRequired: true,
        viewInNavigationPanel: false,
        icon: AnnouncementsIcon,
        exact: false
    },
    {
        systemName: "PRODUCTION_REQUEST.CREATE.TITLE",
        path: "/production/create",
        component: ProductionRequestCreate,
        authRequired: true,
        exact: false,
    },
    {
        systemName: "PRODUCTION_REQUEST.VIEW.TITLE",
        name: 'production-application-view',
        path: "/production/application/:id",
        component: ProductionRequestView,
        authRequired: true,
        viewInNavigationPanel: false,
        exact: false
    },
    {
        systemName: "PRODUCTION_REQUEST.LIST.TITLE",
        path: "/production",
        component: ProductionRequestListWrapper,
        authRequired: true,
        viewInNavigationPanel: true,
        hiddenTitleInNavigationPanel: true,
        icon: ProductionIcon,
        exact: false,
        children: [
            {
                systemName: "PRODUCTION_REQUEST.LIST.TYPE.ORDER.TITLE",
                path: "/production",
                component: ProductionRequestList,
                configData: {
                    type: "order",
                }
            },
            {
                systemName: "PRODUCTION_REQUEST.LIST.TYPE.OFFER.TITLE",
                path: "/production/offer",
                component: ProductionRequestList,
                configData: {
                    type: "offer",
                }
            }
        ]
    },
    {
        systemName: "ISSUES.ISSUE.CREATE.TITLE",
        path: "/issues/create",
        component: CreateIssue,
        authRequired: true,
        viewInNavigationPanel: false,
        exact: true
    },
    {
        systemName: "ISSUES.ISSUE.TITLE",
        path: "/issues/:id",
        component: IssueView,
        authRequired: true,
        viewInNavigationPanel: false,
        exact: true
    },
    {
        systemName: "PLANNER.MY-ISSUES.TITLE",
        path: "/issues",
        component: IssuesList,
        authRequired: true,
        viewInNavigationPanel: true,
        icon: IssuesIcon,
        hiddenTitleInNavigationPanel: true,
        exact: true
    },
    {
        systemName: "INDEX.TITLE",
        path: "/",
        component: Index,
        authRequired: true,
    },
    {
        systemName: "NOT_FOUND.TITLE",
        path: "*",
        component: NotFound,
        exact: true,
    },
];
