import React, {useContext, useMemo} from "react";
import {Amount} from "../../../../models/amount/amount";
import {RootStoreContext} from "../../../../stores/root/root";
import {format} from "./price.helper";

interface Props {
    value: Amount;
}

export const Price: React.FC<Props> = ({value}) => {

    const {
        authStore: { lz },
    } = useContext(RootStoreContext);

    const displayedValue = useMemo(() => format(value, lz.pt),
        [value.value, value.currencyCode, lz.pt]);

    return (
        <span style={{whiteSpace: "nowrap"}}>
            {displayedValue}
        </span>
    );
}