import React, {useContext} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {MergedInvoiceStoreContext} from "../merged.invoice.store";
import {ColumnsType} from "antd/lib/table";
import {InvoiceItem} from "../../../../models/invoices/invoice";
import {Link} from "react-router-dom";
import {Amount} from "../../../../models/amount/amount";
import {Skeleton} from "antd";
import {DetailsTableFooter} from "../../../core/commons/details-table-footer/details.table.footer";
import {generateOrderDetailsTableFooterDataForInvoice} from "../../../../models/invoices/helper/invoices.helper";
import {ExpandedTable} from "../../../core/commons/expanded-table/expanded.table";
import {Price} from "../../../core/commons/price/price";

export const MergedInvoiceDetails: React.FC = observer(() => {
    const store = useContext(MergedInvoiceStoreContext);
    const {t} = useTranslation("translation", {useSuspense: false});

    const columns: ColumnsType<InvoiceItem> = [
        {
            title: t("ORDERS.ORDER.ID"),
            dataIndex: "referenceOrderId",
            key: "merged_invoice_order_detail_referenceOrderId",
            width: 120,
            render: item => item ? <Link className="link" to={"/orders/" + item}>{item}</Link> : null
        },
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "articleName",
            key: "merged_invoice_order_detail_articleName",
            width: "135px",
            render: (article: string) => {
                return article.toUpperCase() === "DELIVERY" ? t("DELIVERY.TITLE") : (
                    <Link
                        className="link"
                        to={"/catalog/article/" + article}
                        style={{whiteSpace: "nowrap"}}
                    >
                        {article}
                    </Link>
                )
            }
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "description",
            key: "merged_invoice_order_detail_description",
            width: "300px"
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "merged_invoice_order_detail_quantity",
            width: "120px",
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "merged_invoice_order_detail_price",
            width: 130,
            render: (price: Amount) => <Price value={price} />
            // render: (price: Amount | undefined, row: OrderDataItems) => price ? displayOrderDetailsPrice({type: row.priceType, price}, t("PRICES." + row.priceType.toUpperCase())) : null
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "totalPrice",
            key: "merged_invoice_order_detail_totalPrice",
            width: 135,
            render: (price: Amount) => <Price value={price} />
        },

    ];
    const columnsConfig = [
        {
            title: "ORDERS.ORDER.ID",
            key: "merged_invoice_order_detail_referenceOrderId"
        },
        {
            title: "ARTICLES.TITLE_SINGLE",
            key: "merged_invoice_order_detail_articleName"
        },
        {
            title: "DESCRIPTION",
            key: "merged_invoice_order_detail_description"
        },
        {
            title: "QUANTITY",
            key: "merged_invoice_order_detail_qty"
        },
        {
            title: "PRICE",
            key: "merged_invoice_order_detail_price"
        },
        {
            title: "TOTAL_PRICE",
            key: "merged_invoice_order_detail_totalPrice"
        },
    ];

    return (
        <ExpandedTable<InvoiceItem>
            title="ORDERS.ORDER.DETAILS.TITLE"
            pageKey="merged_invoice_order_detail"
            columns={columns}
            columnsConfig={columnsConfig}
            tableData={store.loading ? [] : [...store.invoice?.items]}
            tableLoading={store.loading}
            tableFooter={store.loading ?
                <Skeleton active/> :
                <DetailsTableFooter data={generateOrderDetailsTableFooterDataForInvoice(store?.invoice)}/>
            }
        />
    )
})
