import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert, Button, Card, Descriptions, Space, Typography} from "antd";
import {
    ClaimItem,
    claimItemAcceptCardColor,
    ClaimItemAcceptStatus,
} from "../../../../models/claims/claims";
import {Link} from "react-router-dom";
import {ClaimPartsImages} from "./images/claim.parts.images";
import {ClaimsItemAcceptStatus} from "../../status/claim.item.status";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Date} from "../../../core/commons/date/date";
import {YesNo} from "../../../core/commons/yes-no/yes-no";
import {Value} from "../../../core/commons/value/value";
import {ClaimsEditModal} from "../../edit/claims.create.modal";
import {Price} from "../../../core/commons/price/price";

export interface ClaimPartProps {
    item: ClaimItem;
    number?: number;
    onUpdate: () => void;
}

export const ClaimPart: React.FC<ClaimPartProps> = ({item, number, onUpdate}) => {
    const {t} = useTranslation("translation", {useSuspense: false});

    const [showExtra, setShowExtra] = useState<boolean>(false);

    const [editModalShown, setEditModalShown] = useState<boolean>(false);

    const onEditCompleted = async () => {
        onUpdate();
    }

    return (
        <Card
            title={
                <>{number ? (number + ": ") : null}
                    <Link className={"link"} to={"/catalog/article/" + item.article.name}>{item.article.name}</Link></>
            }
            headStyle={{backgroundColor: claimItemAcceptCardColor[item.status] + "12"}}
            style={{borderLeft: "5px solid " + claimItemAcceptCardColor[item.status]}}
            extra={<ClaimsItemAcceptStatus status={item.status} />}
            actions={[
                <Button
                    size={"middle"}
                    icon={<FontAwesomeIcon icon={["fas", "info"]} title={"sdf"} color={"#777"} />}
                    onClick={() => setEditModalShown(true)}
                >
                    {t('EDIT')}
                </Button>
            ]}
        >
            <Space size={[25, 10]} direction={"vertical"}>
                {ClaimItemAcceptStatus.CLARIFICATION_AWAITING === item.status ? (
                    <Alert
                        message={t("CLAIMS.ITEM.REJECT_REASON." + item.rejectionReason.toUpperCase())}
                        showIcon
                        description={item.rejectionExplanation}
                        type="warning"
                        action={
                            <Button size="small" danger onClick={() => setEditModalShown(true)}>
                                {t('ADD_MORE_INFO')}
                            </Button>
                        }
                    />
                ) : null}

                {ClaimItemAcceptStatus.REJECTED === item.status ? (
                    <Alert
                        message={t("CLAIMS.ITEM.REJECT_REASON." + item.rejectionReason.toUpperCase())}
                        showIcon
                        description={item.rejectionExplanation}
                        type="error"
                    />
                ) : null}

                <Descriptions
                    title={t('GENERAL_INFO')}
                    bordered
                    layout={"horizontal"}
                    size={"small"}
                    column={1}
                >
                    <Descriptions.Item label={t('PRICE')}>
                        <Price value={item.priceByItem} />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('QUANTITY_LONG')}>
                        {item.qty} pcs.
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICE.NUMBER_FEBEST')}>
                        {item.invoiceDocumentNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('INVOICES.INVOICE.DATE')}>
                        <Date dateString={item.invoiceDate} showTime />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('CLAIMS.CLAIM.CRASHED_BOX')}>
                        <YesNo value={item.hasCrashedBoxRetention} />
                    </Descriptions.Item>
                    <Descriptions.Item label={t('CLAIMS.CLAIM.BAD_PACKING')}>
                        <YesNo value={item.hasBadPackingRetention} />
                    </Descriptions.Item>
                </Descriptions>
                {showExtra ? (
                    <>
                        <Descriptions
                            title={<>{t('CLAIMS.CLAIM.FITTED_TO')}  <Button size={"small"} onClick={() => setShowExtra(false)}>{t('CLAIMS.CLAIM.HIDE_CLARIFICATION')}</Button></>}
                            bordered
                            layout={"horizontal"}
                            size={"small"}
                            column={2}
                        >
                            <Descriptions.Item label={t('BRAND')} span={2}>
                                <Value value={item.carBrand} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MODEL')} span={2}>
                                <Value value={item.carModel} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.CLARIFICATION')} span={2}>
                                <Value value={item.carModelClarification} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('YEAR')} span={2}>
                                <Value value={item.carModelYear} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.FITTING_DATE')}>
                                <Date dateString={item.fittingDate} showTime />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MILLAGE')}>
                                <Value value={item.fittingOnMillage} />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('CLAIMS.CLAIM.PROBLEM_DATE')}>
                                <Date dateString={item.problemDate} showTime />
                            </Descriptions.Item>
                            <Descriptions.Item label={t('MILLAGE')}>
                                <Value value={item.problemOnMillage} />
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                ): (
                    <Button type={"primary"} size={"small"} onClick={() => setShowExtra(true)}>{t('CLAIMS.CLAIM.SHOW_CLARIFICATION')}</Button>
                ) }
                <div>
                    <Typography.Title level={4} style={{fontSize: '16px'}}>{t('DESCRIPTION')}</Typography.Title>
                    {item.description}
                </div>
                <ClaimPartsImages item={item} />
            </Space>

            {editModalShown ? (
                <ClaimsEditModal
                    onComplete={onEditCompleted}
                    onCancel={() => setEditModalShown(false)}
                    item={item}
                />
            ) : null}
        </Card>
    );
}