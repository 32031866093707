import React from "react";
import "./catalog.product.cart.price.scss"
import {CatalogArticle} from "../../../../../models/articles/catalog/search";
import {Price} from "../../../../core/commons/price/price";

interface CatalogProductCartPriceProps {
    article: CatalogArticle;
}

export const CatalogProductCartPrice: React.FC<CatalogProductCartPriceProps> = ({article}) => {

    return (
        <div className={"catalog-product-price"}>
            <div className={"catalog-product-price-value"}>
                <Price value={article.amount} />
            </div>
            <div className={"catalog-product-price-hint"}>
                price per item (without TAX)
            </div>
        </div>
    )
}