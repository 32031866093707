import React, {useContext} from "react";
import {observer} from "mobx-react";
import {ColumnsType} from "antd/lib/table";
import {Amount} from "../../../../models/amount/amount";
import {displayCustomPrice} from "../../../../utils/helpers";
import {useTranslation} from "react-i18next";
import {CartCreateCalculatedPrice} from "../../../../models/cart/create/cart.create";
import {Alert, Space, Typography, Table} from "antd";
import {useVT} from "virtualizedtableforantd4";
import { Link } from "react-router-dom";
import {CartStoreContext} from "../cart.create.store";
import {generateCartMissingItemsTableDataItem} from "../../../../models/cart/helper/cart.helper";
import {Price} from "../../../core/commons/price/price";

export const CartCreateMissingItems: React.FC = observer(() => {
    const store = useContext(CartStoreContext);
    const {t} = useTranslation('translation', {useSuspense: false});

    const columns: ColumnsType<any> = [
        {
            title: t("ARTICLES.NAME"),
            dataIndex: "article_name",
            key: "article_name",
            width: "130px",
            render: (name: string) => !name ? null : name.toUpperCase() === "DELIVERY" ? t("DELIVERY.TITLE") : (
                <Link className="link" to={"/catalog/article/" + name}>{name}</Link>
            )
        },
        {
            title: t("ARTICLES.DESCRIPTION"),
            dataIndex: "article_description",
            key: "articleDescription",
            width: "300px"
        },
        {
            title: t("PRICE"),
            dataIndex: "price",
            key: "price",
            align: "right",
            width: "150px",
            render: (item: CartCreateCalculatedPrice | undefined) => item ? displayCustomPrice({
                price: item.amount
            }) : null
        },
        {
            title: t("QUANTITY"),
            dataIndex: "quantity",
            key: "quantity",
            width: "100px"
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "total_amount",
            key: "total_amount",
            width: "150px",
            render: (item: Amount | undefined) => item ? <Price value={item} /> : null
        }
    ];

    const y = 500;
    const [vt] = useVT(() => ({scroll: {y}, debug: true}), []);

    return (
        <Space direction="vertical" size={[20, 20]}>
            <Alert message={t("ORDER.CREATED.WITH_MISSING_ITEMS")} type="warning"/>
            <Link
                to="/cart/checkout"
                className="ant-btn ant-btn-primary"
            >
                {t("ORDER.PROCEED_WITH_CHECKOUT")}
            </Link>
            <Typography.Title level={4} style={{margin: 0}}>{t("MISSING_ITEMS")}</Typography.Title>
            <Table
                rowKey={"id"}
                columns={columns}
                components={vt}
                dataSource={store.missingItems.map(item => generateCartMissingItemsTableDataItem(item))}
                loading={store.loading}
                scroll={{x: true, y}}
                pagination={false}
            />
            <Link
                to="/cart/checkout"
                className="ant-btn ant-btn-primary"
            >
                {t("ORDER.PROCEED_WITH_CHECKOUT")}
            </Link>
        </Space>
    )
})
