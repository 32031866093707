import {formatCurrencyCode, replaceDotToComma, splitNumber} from "../../../../models/amount/amount.helper";
import {Amount} from "../../../../models/amount/amount";

export enum DisplayType {
    DOT = 'dot',
    COMMA = 'comma'
}

export const formatWithDot = (value: Amount): string => {
    return splitNumber(value.value.toFixed(2)) + " " + formatCurrencyCode(value.currencyCode);
}

export const formatWithComma = (value: Amount) => {
    return replaceDotToComma(splitNumber(value.value.toFixed(2)))  + " " + formatCurrencyCode(value.currencyCode);
}

export const format = (value: Amount, type: DisplayType): string => {
    switch (type) {
        case DisplayType.DOT:
            return formatWithDot(value);
        case DisplayType.COMMA:
            return formatWithComma(value);
        default:
            return value.value.toFixed(2);
    }
}