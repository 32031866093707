import React from "react";
import {useTranslation} from "react-i18next";
import {Card} from "antd";
import {Claim} from "../../../../models/claims/claims";
import {CustomCard} from "../../../core/commons/custom-card/custom.card";
import {Date} from "../../../core/commons/date/date";
import {Price} from "../../../core/commons/price/price";

export interface ClaimDescriptionProps {
    claim: Claim;
}

export const ClaimDescription: React.FC<ClaimDescriptionProps> = ({claim}) => {
    const {t} = useTranslation('translation', {useSuspense: false});

    return (
        <div className="data-descriptions-with-edit-wrapper">
            <div className="data-content-wrapper">

                <Card
                    title={t('INFORMATION')}
                    className="card"
                    bordered={false}
                    size="small"
                >
                    <CustomCard
                        data={claim}
                        rowKeys={[
                            {
                                key: 'documentDate',
                                label: 'DATE',
                                render: value => <Date dateString={value}/>
                            },
                            {
                                key: 'grandTotal',
                                label: 'TOTAL_AMOUNT',
                                render: value => <Price value={value} />
                            }
                        ]}/>
                </Card>

            </div>
        </div>
    );
}