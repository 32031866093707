import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { Amount } from "../../../models/amount/amount";
import { Date } from "../../core/commons/date/date";
import { FilterKey } from "../../../models/filtered-table/filtered.table";
import FilteredTable from "../../core/commons/filtered-table/filtered.table";
import { invoicesService } from "../../../services/invoices/invoices.service";
import { Warehouse } from "../../../models/warehoues/warehouse";
import { warehousesService } from "../../../services/warehouses/warehouses.service";
import { ComponentPropsFromRoute } from "../../../routers/routers";
import {Price} from "../../core/commons/price/price";

interface InvoicesListProps extends ComponentPropsFromRoute {}
export const InvoicesList: React.FC<InvoicesListProps> = ({ name }) => {
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    useEffect(() => {
        try {
            warehousesService.getWarehouses().then((warehouses) => setWarehouses(warehouses));
        } catch (e) {
            console.log(e);
        }
    }, []);

    const { t } = useTranslation("translation", { useSuspense: false });
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <Link className="link" to={"/invoices/" + id}>
                    {id}
                </Link>
            ),
        },
        {
            title: t("INVOICE.NUMBER"),
            dataIndex: "docNumber",
            key: "invoices_number",
            render: (item: string) => <span style={{ whiteSpace: "nowrap" }}>{item}</span>,
        },
        {
            title: t("WAREHOUSES.WAREHOUSE_TITLE"),
            dataIndex: "warehouse",
            key: "invoices_warehouse",
            render: (warehouse: Warehouse) => warehouse.name,
        },
        {
            title: t("PRICE"),
            dataIndex: "subtotal",
            key: "invoices_subtotal",
            render: (amount: Amount) => <Price value={amount} />,
        },
        {
            title: "VAT",
            dataIndex: "subtotalVat",
            key: "invoices_subtotalVat",
            render: (amount: Amount) => <Price value={amount} />,
        },
        {
            title: t("GRAND_TOTAL_PRICE"),
            dataIndex: "grandTotal",
            key: "invoices_grandTotal",
            render: (total: Amount) => <Price value={total} />,
        },
        {
            title: t("CREATED_AT"),
            dataIndex: "createdAt",
            key: "invoices_createdAt",
            render: (date: string) => <Date dateString={date} showTime />,
        },
    ];
    const columnsConfigs = [
        {
            title: "ID",
            key: "id",
        },
        {
            title: "ORDERS.ORDER.ID",
            key: "invoices_order",
        },
        {
            title: "INVOICE.NUMBER",
            key: "invoices_number",
        },
        {
            title: "PACKING_LIST",
            key: "invoices_packing_list",
        },
        {
            title: "WAREHOUSES.WAREHOUSE_TITLE",
            key: "invoices_warehouse",
        },
        {
            title: "PRICE",
            key: "invoices_subtotal",
        },
        {
            title: "VAT.TITLE",
            key: "invoices_subtotalVat",
        },
        {
            title: "GRAND_TOTAL_PRICE",
            key: "invoices_grandTotal",
        },
        {
            title: "CREATED_AT",
            key: "invoices_createdAt",
        },
    ];
    const filters: FilterKey[] = [
        {
            key: "id",
            label: "ID",
            type: "string",
        },
        {
            key: "orderId",
            label: t("ORDERS.ORDER.TITLE"),
            type: "string",
        },
        {
            key: "number",
            label: t("INVOICES.NUMBER"),
            type: "string",
        },
        {
            key: "packingList",
            label: t("PACKING_LIST"),
            type: "string",
        },
        {
            key: "warehouses",
            label: t("WAREHOUSES.WAREHOUSE_TITLE"),
            type: "select",
            options: warehouses.map((item) => ({ label: item.name, value: item.id })),
        },
        {
            key: "grandTotal",
            label: t("TOTAL_AMOUNT"),
            type: "int",
        },
        {
            key: "clientPaymentTerms",
            label: t("PAYMENT_TERMS"),
            type: "int",
        },
        {
            key: "createdAt",
            label: t("CREATED_AT"),
            type: "date",
        },
    ];

    return (
        <Space direction="vertical" size={[30, 30]} className="content-inner">
            <FilteredTable
                rowKey="id"
                filterKeys={filters}
                columns={columns}
                columnsConfigs={{ columns: columnsConfigs, pageKey: name! }}
                loadDataCallback={(request) => invoicesService.getInvoices(request)}
            />
        </Space>
    );
};
