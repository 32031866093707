import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/lib/table/interface";
import {ClaimItem} from "../../../../../models/claims/claims";
import {Link} from "react-router-dom";
import React from "react";
import {Button} from "antd";
import {ClaimsItemAcceptStatus} from "../../../status/claim.item.status";
import {Date} from "../../../../core/commons/date/date";
import {Price} from "../../../../core/commons/price/price";

export const columnsFactory = (t: TFunction<"translation">, openItemCart: (item: ClaimItem) => void): ColumnsType<ClaimItem> => {
    return [
        {
            title: "#",
            key: "number",
            render: (_, __, number) => number + 1
        },
        {
            title: t('ARTICLES.TITLE_SINGLE'),
            dataIndex: "article",
            key: "article",
            render: article => <Link className={"link"} to={"/articles/" + article.name}>{article.name}</Link>
        },
        {
            title: t("STATUS"),
            dataIndex: "status",
            key: "status",
            render: status => <ClaimsItemAcceptStatus status={status} />
        },
        {
            title: t("TOTAL_AMOUNT"),
            dataIndex: "priceByItem",
            key: "priceByItem",
            render: price => <Price value={price} />
        },
        {
            title: t('QUANTITY_LONG'),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t('INVOICE.NUMBER_FEBEST'),
            dataIndex: "invoiceDocumentNumber",
            key: "invoiceDocumentNumber",
            render: (number, item) => <Link className={"link"} to={"/articles/" + item.article.name}>{number}</Link>
        },
        {
            title: t('INVOICES.INVOICE.DATE'),
            dataIndex: "invoiceDate",
            key: "invoiceDate",
            render: (date) => <Date dateString={date} showTime />
        },
        {
            key: "actions",
            render: (_, item) => <Button size={"small"} onClick={() => openItemCart(item)}>{t('SHOW_DETAILS')}</Button>
        }
    ];
}