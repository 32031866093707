import {Amount} from "./amount";

export const currencies: { [K: string]: string } = {
    EUR: '€',
    GBP: '₤',
    USD: '$',
    AED: 'AED'
}

export const splitNumber = (inputNumber: number | string): string => {
    return typeof inputNumber === "string" ? inputNumber.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : String(inputNumber).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const replaceDotToComma = (value: string): string => {
    return value.replace(".", ",");
}

export const formatCurrencyCode = (code: string): string => {
    const ucCode = code.toUpperCase();
    return currencies[ucCode] || ucCode;
}