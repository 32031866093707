import API from "../../utils/api";
import { notification } from "antd";
import { AuthStore } from "../../stores/auth/auth.store";
import { AuthService } from "../../services/auth/auth.service";
import { SystemUser } from "../../models/user/user";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponseData } from "../../models/core/response";
import history from "../../utils/history";
import { UserService } from "../../services/user/user.service";
import * as Sentry from "@sentry/react";

export class LoginController {
    static KEY_BEARER = "BEARER";
    static KEY_FAKE_USER = "ffu";

    constructor(
        private readonly _authStore: AuthStore,
        private readonly _authService: AuthService,
        private readonly _userService: UserService
    ) {
        this.logout = this.logout.bind(this);
        // this.tryLoadFakeUser();
        this.init();
    }

    async login(values: SystemUser): Promise<void> {
        this._authStore.setLogging(true);
        try {
            const token: string = (
                await this._authService.auth({ ...values, password: values.password ? values.password.trim() : "" })
            ).token;
            this._authStore.setToken(token);
            await this.loadUser();
            localStorage.setItem("registered", "1");
            this._authStore.setLogging(false);
            history.push("/catalog");
        } catch (e: any) {
            this._authStore.setLogging(false);
            this._authStore.setInited(true);
            notification.error({ message: "Error", description: e.response.data.message });
        }
    }

    public async loadUser(): Promise<void> {
        try {
            const { user, attributes, branch, accesses, selectedAccess, originalUser, closed, selectedCurrencyCode, currencyCodes, lz } = await this._userService.getCurrentUserWithAttributes();
            this._authStore.setUser(user);
            this._authStore.setOriginalUsername(originalUser);
            Sentry.setUser({ email: this._authStore.user?.email, id: this._authStore.user?.id + "" });
            this._authStore.setAttributes(attributes);
            this._authStore.setUserBranch(branch);
            this._authStore.setAccess(selectedAccess);
            this._authStore.setAccesses(accesses);
            this._authStore.setClosed(closed);
            this._authStore.setSelectedCurrencyCode(localStorage.getItem('cc') || selectedCurrencyCode);
            this._authStore.setCurrencyCodes(currencyCodes);
            this._authStore.setLZ(lz)
            this._authStore.setInited(true);
        } catch (e) {
            this._authStore.setInited(true);
        }
    }

    private tryLoadUser(): void {
        this.loadUser();
    }

    async logout() {
        await this._authService.logout();
        this._authStore.setIsInvalidCredentials(null);
        this._authStore.setUser(null);
        this._authStore.setUserBranch("");
        this._authStore.setAccess(null);
        Sentry.configureScope((scope) => scope.setUser(null));
        history.push("/login");
    }

    async switchRole(access: number) {
        await this._authService.switchAccount(access);
        window.location.reload();
    }

    async setCurrencyCode(currencyCode: string) {
        this._authStore.selectedCurrencyCode = currencyCode;
        localStorage.setItem('cc', currencyCode);
        window.location.reload();
    }

    // private removeAuthCookie(): void {
    //     document.cookie = AuthStore.KEY_BEARER + "=";
    // }

    private initUnauthorizedInterceptor() {
        API.interceptors.response.use(
            (value: AxiosResponse): AxiosResponse => {
                return value;
            },
            (error: AxiosError<ErrorResponseData>) => {
                if (error.response?.status === 401 && window.location.pathname !== "/login" && window.location.pathname !== "/forgot-password" && window.location.pathname !== "/sign-up") {
                    this.logout();
                }
                return Promise.reject(error);
            }
        );
    }

    private init(): void {
        this.initUnauthorizedInterceptor();
        this.tryLoadUser();
    }
}
